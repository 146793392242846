<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <div class="py-4">
      <b-card>
        <b-row>
          <b-col lg="6">
            <input class="form-control" placeholder="Type to Search" type="search" @input="actionSearch"
              v-on:keyup.enter="actionSearch" />
            <span v-if="typing"> <em> typing .. </em> </span>
          </b-col>
        </b-row>
        <div class="py-2 text-right">
          <span class="pr-2">
            <router-link class="btn btn-sm btn-dark" :to="{ name: PublisherEmailCreatePath.name }">
              <i class="fa fa-plus"></i>
            </router-link>
          </span>
          <span>
            <button class="btn btn-sm btn-dark" @click="actionGetEmails">
              <i class="fa fa-refresh"></i>
            </button>
          </span>
          <span class="" style="vertical-align: bottom;">
            Count <strong>{{ totalRows }}</strong>
          </span>
        </div>
        <b-row>
          <b-col>
            <div style="white-space: nowrap;">
              <b-table sticky-header="600px" responsive="xl" show-empty :busy="isLoading" hover :fields="fields"
                :items="emails">
                <template #cell(name)="row">
                  <div class="p-auto">
                    {{ row.item.name }}
                    <router-link class="btn btn-sm btn-default"
                      :to="{ name: PublisherEmailUpdatePath.name, params: { id: row.item.id } }">
                      <i class="fa fa-edit"></i>
                    </router-link>
                  </div>
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="limit" aria-controls="my-table" />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { PublisherEmailCreatePath, PublisherEmailUpdatePath } from '../../../router/account';
export default {
  data() {
    return {
      PublisherEmailCreatePath,
      PublisherEmailUpdatePath,
      query_search: null,
      limit: 50,
      currentPage: 1,
      typing: false,
      fields: [
        {
          key: 'name',
          sortable: true
        },
        { key: 'email' },
        { key: 'scoop' },
        { key: 'is_active' },
        { key: 'admin' },
        { key: 'vendor' },
        { key: 'asign_by' },
      ]
    }
  },
  mounted() {
    this.actionGetEmails();
  },
  watch: {
    currentPage: function () {
      this.actionGetEmails();
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.emailReport.isLoading,
      isError: (state) => state.emailReport.isError,
      emails: (state) => state.emailReport.items,
      totalRows: (state) => state.emailReport.totalRows,
    }),
  },
  methods: {
    ...mapActions("emailReport", ["fetchListEmail"]),
    actionGetEmails() {
      this.fetchListEmail({
        q: this.query_search,
        limit: this.limit,
        page: this.currentPage,
      });
    },
    actionSearch(event) {
      const val = event.target.value;
      this.query_search = val;
      this.currentPage = 1;
      if (!val.trim()) {
        this.actionGetEmails();
        clearTimeout(this.debounce);
        return;
      }
      this.typing = true;
      this.isLoadingSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = false;
        this.actionGetEmails();
      }, 600); this
    },
  },
}
</script>

<style scoped>

</style>